<template>
  <div
    class="app header-fixed sidebar-fixed aside-menu-fixed aside-menu-hidden"
  >
    <AdminHeader v-if="name.length>0" :name="name" />

    <div class="app-body">
      <!-- SIDE -->
      <sidebar  v-if="name.length>0" />
      <!-- Main content -->

      <div class="main" style="margin-top: 0px" v-if="is_loading">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/admin/sidebar.vue";
import AdminHeader from "@/components/admin/AdminHeader.vue";

//require("../assets/fontawesome/css/all.min.css");

import "@fortawesome/fontawesome-free/css/all.css";
import "sweetalert2/dist/sweetalert2.min.css";

require("../assets/admin/css/simple-line-icons.css");
require("../assets/admin/css/style.css");

import { editorConfig } from "@/components/library/editorConfig.js";
import { editorConfigVideo } from "@/components/library/editorVideo.js";
import { editorConfigVideo_En } from "@/components/library/editorConfigVideo_En.js";


import * as MyFn from "@/components/library/editorConfig_2.js";



import axios from 'axios';





import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule,
      rules[rule]);
});



import { messages } from 'vee-validate/dist/locale/vi.json';

for (let [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
    message: messages[rule] 
  });
}


export default {
  name: "Admin",
  data(){
    return {
      name:"",
      is_loading:false
    }
  },
  components: {
    sidebar,
    AdminHeader,
  },
  created(){

                 window.editorConfigVideo = editorConfigVideo;
                  window.editorConfigVideo_EN = editorConfigVideo_En;  
                    
                  window.editorConfig = editorConfig;
                  window.editorConfig_EN = MyFn.editorConfig_2();
                  window.editorConfig_ques = MyFn.editorConfig_2();
                  window.editorConfig_ques_EN = MyFn.editorConfig_2();
                  window.editorConfig_asome = MyFn.editorConfig_2();
                  window.editorConfig_asome_EN = MyFn.editorConfig_2();

     this.$session.start()
     if(!this.$session.has("isLogin")){
          
           if (localStorage.mylogin) {
             console.log("BUITIN", JSON.parse(localStorage.mylogin ) );
              this.$session.set("isLogin", JSON.parse(localStorage.mylogin ) );

           }

         
    
       
      }

      if(!this.$session.has("isLogin")){

            this.$router.push({name:"admin.login"});

      }else{



         var user= this.$session.get("isLogin");
         user['language'] = this.$route.query.lang!=undefined ? this.$route.query.lang:  "vn";
         window.my_header= user;



              console.log("USERNAME HEADER: ",user);
             axios.get(window.my_api +"api/user/get-user-by-id?userId="+user.userID,
                         {                          headers: {
                                                            language: this.$i18n.locale
                                                          }
                          }
                  
        )
             .then( (res)=>{
                   
              

                    this.$session.set("Customer", res.data.data);
                    this.name=res.data.data.username;

                    this.is_loading=true;
                
             })

         //console.log("HEader",window.my_header);
      }

  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "/layout/jquery.js");
    document.head.appendChild(recaptchaScript);
    setTimeout(function () {
      let recaptchaScript1 = document.createElement("script");
      recaptchaScript1.setAttribute("src", "/layout/bootstrap.min.js");
      document.head.appendChild(recaptchaScript1);

      let recaptchaScript2 = document.createElement("script");
      recaptchaScript2.setAttribute("src", "/layout/admin/js/app.js");
      document.head.appendChild(recaptchaScript2);

      // let recaptchaScript3 = document.createElement("script");
      // recaptchaScript3.setAttribute("src", "/layout/ckeditor/ckeditor.js");
      // document.head.appendChild(recaptchaScript3);

      // let recaptchaScript4 = document.createElement("script");
      // recaptchaScript4.setAttribute("src", "/layout/ckeditor/function.js");
      // document.head.appendChild(recaptchaScript4);

      let recaptchaScript5 = document.createElement("script");
      recaptchaScript5.setAttribute("src", "/layout/admin.js");
      document.head.appendChild(recaptchaScript5);
    }, 100);
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Admin | Bến xe miền đông ",
    // all titles will be injected into this template
    titleTemplate: "%s | Bến xe miền đông",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>



