<template>
 

  <div class="sidebar">


            <div class="sidebar-nav"  v-if="is_sidebar==1" >
                <ul class="nav">
                   
                    <li class="nav-item">
                       
                         <router-link class="nav-link" to="/admin/bus/lists">
                               <i class="fad fa-bus"></i>
                                 Tuyến xe bus
                         </router-link>

                    </li>

                    


                     <li class="nav-item">
                          
                            <router-link class="nav-link" target="_black" to="/admin/vehicle/lists">
                                     <i class="fas fa-plus"></i>
                                        Quản lý tuyến xe 
                            </router-link>


                    </li>
                    

                    <li class="nav-item nav-dropdown open">
                        <a class="nav-link nav-dropdown-toggle" href="#">
                            <i class="fas fa-newspaper"></i>
                         Gian Hàng </a>
                        <ul class="nav-dropdown-items">

                            <li class="nav-item">
                              
                                   <router-link class="nav-link" to="/admin/booth/lists">
                                     <i class="fas fa-plus"></i>
                                        Thuê mặt bằng 
                                 </router-link>
                            </li>
                            <li class="nav-item"  v-if="Customer!=null">
                       
                                  <router-link class="nav-link" to="/admin/ticket/lists">
                                       <i class="fab fa-black-tie"></i>
                                        Thuê quầy vé 
                                  </router-link>
                            </li>
                                    
                        </ul>
                    </li>

                    <li class="nav-item">
                        

                         <router-link class="nav-link" to="/admin/business/lists">
                             <i class="fas fa-cars"></i>
                                Doanh nghiệp vận tải
                         </router-link>


                    </li>

                    <li class="nav-item">

                         <router-link class="nav-link" to="/admin/location/lists">
                              <i class="fas fa-map-marked-alt"></i>
                                Tỉnh, Thành phố
                         </router-link>
                    </li>


                  </ul>
                   

            </div>


            <div class="sidebar-nav" v-if="is_sidebar==2">
                <ul class="nav">
                    <li class="nav-item">
                      


                           <router-link class="nav-link" to="/admin/index/index"><i class="fad fa-home"></i> Thông tin chung <span class="badge badge-primary">Trang chủ </span></router-link>
                    </li>
                  
                    <li class="nav-item">
                       
                    
                          <router-link class="nav-link" to="/admin/department/lists">
                        <i class="fas fa-person-booth"></i>
                             Phòng Ban
                         </router-link>

                          
                    </li>

                    

                     <li class="nav-item nav-dropdown open">
                         <a class="nav-link nav-dropdown-toggle" href="#">
                            <i class="fa fa-newspaper"></i>
                         Tin tức </a>
                         <ul class="nav-dropdown-items">
                             <li class="nav-item open"  v-if="Customer!=null && Customer.isRole=='1'">
                                 <router-link class="nav-link" to="/admin/category/lists">
                             <i class="" style="margin:2px 15px;"></i>
                            Danh mục
                         </router-link>
                            </li>
                            <li class="nav-item open">
                                 <router-link class="nav-link" to="/admin/news/lists">
                             
                              <i class="" style="margin:2px 15px;"></i>
                              Tin tức
                         </router-link>
                            </li>


                        
                         </ul>
                        
                    </li>


                    <li class="nav-item">
                        
                         <router-link class="nav-link" to="/admin/supplier/lists">
                             <i class="fas fa-ticket-alt"></i>
                             Đối tác khách hàng
                         </router-link>
                    </li>


                    
                     <li class="nav-item">

                         <router-link class="nav-link" to="/admin/connection/lists">
                             <i class="fas fa-plug"></i>
                             Liên kết website  
                         </router-link>
                    </li>
                   

                     <li class="nav-item">
                        

                         <router-link class="nav-link" to="/admin/albums/lists">
                             <i class="fas fa-compact-disc"></i>
                             Album
                         </router-link>
                    </li>

                     <li class="nav-item">
                        
                         <router-link class="nav-link" to="/admin/banner/lists">
                            <i class="fas fa-images"></i>
                             Banner
                         </router-link>
                    </li>
                   
                    <li class="nav-item">
                        
                         <router-link class="nav-link" to="/admin/slideshow/lists">
                            <i class="fas fa-portrait"></i>
                             Slideshow
                         </router-link>
                    </li>
                   
                    
                <li class="nav-item">
                        


                         <router-link class="nav-link" to="/admin/page/lists">
                            <i class="fas fa-pager"></i>
                                Các Trang 
                         </router-link>


                    </li>
                   


                    
                    
                    
                   
                    
                 
                    <li class="nav-item">
                       
                          <router-link class="nav-link" to="/admin/question/lists">
                               <i class="fab fa-black-tie"></i>
                                Hỏi đáp 
                          </router-link>
                    </li>

                   
                   
                    <li class="nav-item nav-dropdown open">
                         <a class="nav-link nav-dropdown-toggle" href="#">
                            <i class="fa fa-newspaper"></i>
                         FORM  </a>
                         <ul class="nav-dropdown-items">
                             <li class="nav-item open">
                                 <router-link class="nav-link" to="/admin/contact/lists">
                             <i class="" style="margin:2px 15px;"></i>
                            Liên hệ
                         </router-link>
                            </li>
                            <li class="nav-item open">
                                 <router-link class="nav-link" to="/admin/commentus/lists">
                             
                              <i class="" style="margin:2px 15px;"></i>
                              Ý kiến khách hàng 
                                     </router-link>
                            </li>
                            <li class="nav-item open">
                                 <router-link class="nav-link" to="/admin/advertising/lists">
                             
                              <i class="" style="margin:2px 15px;"></i>
                               Thuê quảng cáo  
                                     </router-link>
                            </li>


                        
                         </ul>
                        
                    </li>

                    <li class="nav-item" v-if="Customer!=null && Customer.isRole=='1'">
                       

                          <a href="/admin/roles/lists" class="nav-link" >
                               <i class="fad fa-user"></i>  Phân quyền 
                          </a>
                    </li>
                   
                    <li class="nav-item"  v-if="Customer!=null && Customer.isRole=='1'">
                       

                          <router-link class="nav-link" to="/admin/user/lists">
                               <i class="fad fa-user"></i>  Quản lý thành viên
                          </router-link>
                    </li>
                  </ul>
                   

            </div>



            <div class="sidebar-nav"  v-if="is_sidebar==3" >
                <ul class="nav">
                   
                    <li class="nav-item">
                       
                         <router-link class="nav-link" to="/admin/bus/lists">
                               <i class="fad fa-bus"></i>
                                 Tuyến xe bus
                         </router-link>

                    </li>

                    

                     <li class="nav-item">
                    
                         <router-link class="nav-link" to="/admin/station/lists">
                               <i class="fad fa-charging-station"></i>
                            Bến xe 
                         </router-link>


                    </li>

                       <li class="nav-item nav-dropdown open">
                        <a class="nav-link nav-dropdown-toggle" href="#"> 
                            <i class="fad fa-truck-pickup"></i>
                                 Tuyến xe</a>
                        <ul class="nav-dropdown-items">
                            <li class="nav-item">
                                

                                 <router-link class="nav-link" target="_black" to="/admin/vehicle/lists">
                                     <i class="fas fa-plus"></i>
                                        Quản lý tuyến xe 
                                 </router-link>
                            </li>
                            <li class="nav-item">
                              


                                 <router-link class="nav-link" target="_black" to="/admin/service/lists">
                                     <i class="fas fa-plus"></i>
                                        Quản lý chuyến xe 
                                 </router-link>
                            </li>
                            <li class="nav-item">



                                 <router-link class="nav-link" target="_black" to="/admin/service/import">
                                     <i class="fas fa-plus"></i>
                                        Nhập Excel
                                 </router-link>
                            </li>
                            <li class="nav-item">

                                 <router-link class="nav-link" target="_black" to="/admin/service/export">
                                     <i class="fas fa-plus"></i>
                                        Xuất Excel
                                 </router-link>
                            </li>
                        </ul>
                    </li>
                    

                    <li class="nav-item nav-dropdown open">
                        <a class="nav-link nav-dropdown-toggle" href="#">
                            <i class="fas fa-newspaper"></i>
                         Gian Hàng </a>
                        <ul class="nav-dropdown-items">
                            <li class="nav-item">
                              
                                   <router-link class="nav-link" to="/admin/booth/lists">
                                     <i class="fas fa-plus"></i>
                                        Cho thuê
                                 </router-link>
                            </li>
                            
                             <li class="nav-item">
                                

                                 <router-link class="nav-link" to="/admin/booth/import">
                                     <i class="fas fa-plus"></i>
                                        Nhập Excel
                                 </router-link>
                            </li>
                             <li class="nav-item">
                                
                                 <router-link class="nav-link" target="_black" to="/admin/booth/export">
                                     <i class="fas fa-plus"></i>
                                        Xuất Excel
                                 </router-link>
                            </li>
                            
                        </ul>
                    </li>

                    <li class="nav-item">
                        

                         <router-link class="nav-link" to="/admin/business/lists">
                             <i class="fas fa-cars"></i>
                                Công ty vận tải 
                         </router-link>


                    </li>

                    <li class="nav-item">

                         <router-link class="nav-link" to="/admin/location/lists">
                              <i class="fas fa-map-marked-alt"></i>
                                Tỉnh, Thành phố
                         </router-link>
                    </li>


                  </ul>
                   

            </div>


            
   </div>
</template>


<script>

export default {
  data(){
    return {
        is_sidebar:2,
        Customer:null
    }

  },
  created(){
        
        this.Customer= this.$session.get("Customer");
            if(this.Customer==null){
                this.Customer={isRole:'1'};
            }
        this.is_sidebar=this.$route.meta.is_side || 2;
  },
  watch:{
        '$route'(to){
            this.is_sidebar = to.meta.is_side || 2;
            this.Customer= this.$session.get("Customer");
        }
  }

 
}
</script>

